import {
  AppBar,
  Avatar,
  Box,
  Breakpoint,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthStatus, useAuth } from "../../context/auth";
import logo from "../../static/logo_name.svg";
import { HomebaseButton } from "../HomebaseButton";
import React from "react";

interface HeaderProps {
  maxWidth: Breakpoint;
}

export const Header = ({ maxWidth }: HeaderProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { logout, authStatus, attributes } = useAuth();
  const navigate = useNavigate();
  const isHome = window.location.pathname !== "/";
  const theme = useTheme();
  const location = useLocation();

  const loginUrl = `/login?redirect=${encodeURIComponent(
    location.pathname + location.search
  )}${theme.palette.mode === "dark" ? "&referrer=empiredao" : ""}`;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (path: string) => {
    setAnchorEl(null);
    if (typeof path === "string") {
      navigate(path);
    }
  };

  return (
    <AppBar
      position="relative"
      style={{
        backgroundColor: theme.palette.mode === "dark" ? "#121212" : "white",
        borderWidth: 0,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderBottomWidth: "thin",
        boxShadow: "none",
        zIndex: 3,
      }}
      variant="outlined"
    >
      <Container maxWidth={maxWidth}>
        <Toolbar>
          {isHome && (
            <a
              href="/"
              style={{ position: "absolute", left: 0, textDecoration: "none" }}
            >
              <Typography
                variant="body1"
                className="clickable"
                style={{ color: "#9b9999" }}
              >
                {"< Homes"}
              </Typography>
            </a>
          )}
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <a href="/">
              <img src={logo} alt="logo" className="header-logo clickable" />
            </a>
          </Box>
          {authStatus === AuthStatus.SignedIn ? (
            <>
              <IconButton
                sx={{ position: "absolute", right: 0 }}
                onClick={handleOpen}
              >
                <Avatar
                  sx={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  {attributes.firstName.charAt(0)}
                </Avatar>
              </IconButton>
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableScrollLock={true}
              >
                <a
                  href="/dashboard"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem>Dashboard</MenuItem>
                </a>
                <a
                  href="/dashboard/settings"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem onClick={() => handleClose("/dashboard/settings")}>
                    Settings
                  </MenuItem>
                </a>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <HomebaseButton href={loginUrl}>Login</HomebaseButton>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
