import { API, Auth, Hub } from "aws-amplify";
import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetUserQuery, User } from "../API";
import * as queries from "../graphql/queries";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

interface IAuthContext {
  authStatus: AuthStatus;
  user: any;
  attributes: User;
  updateUserAttributes: (attributes: User) => void;
  confirmSignUp: (email: string, code: string) => void;
  login: (email: string, pwd: string) => void;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [attributes, setAttributes] = useState({} as User);
  const [user, setUser] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthStatus = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        await getUserAttributes(user.attributes.sub);
        setUser(user);
        setAuthStatus(AuthStatus.SignedIn);
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "w2tcgqa7",
          name: user.attributes["custom:first_name"],
          email: user.attributes["email"],
          KYC:
            parseInt(user.attributes["custom:kycStatus"]) === 3 ? true : false,
        });
      } catch (error) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    };

    getAuthStatus();
  }, []);

  useEffect(() => {
    Hub.listen("auth", async ({ payload }: { payload: any }) => {
      const { event } = payload;
      switch (event) {
        case "signIn":
          break;
        case "signOut":
          break;
        case "autoSignIn":
          const user = payload.data;
          await getUserAttributes(user.attributes.sub);
          setUser(user);
          setAuthStatus(AuthStatus.SignedIn);
          break;
        default:
          break;
      }
    });
  }, [navigate]);

  const getUserAttributes = async (userId: string) => {
    try {
      const res = (await API.graphql({
        query: queries.getUser,
        variables: { id: userId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as GraphQLResult<GetUserQuery>;
      if (res.data?.getUser) {
        setAttributes(res.data.getUser);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserAttributes = async (attributes: User) => {
    setAttributes(attributes);
  };

  const confirmSignUp = async (email: string, code: string) => {
    try {
      await Auth.confirmSignUp(email, code);
    } catch (error) {
      console.log("Error confirming signup", error);
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const user = await Auth.signIn(email, password);
      await getUserAttributes(user.attributes.sub);
      setUser(user);
      setAuthStatus(AuthStatus.SignedIn);
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "w2tcgqa7",
        name: user.attributes["custom:first_name"],
        email: user.attributes["email"],
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setAuthStatus(AuthStatus.SignedOut);
      setAttributes({} as User);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        user,
        attributes,
        updateUserAttributes,
        confirmSignUp,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProtectedPage = ({ children }: { children: JSX.Element }) => {
  const { authStatus, attributes } = useAuth();
  const location = useLocation();

  if (authStatus === AuthStatus.SignedOut) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  analytics.identify(attributes.id, {
    email: attributes.email,
    firstName: attributes.firstName,
    lastName: attributes.lastName,
    country: attributes.country,
    kycStatus: attributes.kycStatus,
    isAccredtied: attributes.isAccredited,
    accreditedStatus: attributes.accreditedStatus,
    approved: attributes.approved,
  });

  return children;
};

export const SignedOutPage = ({ children }: { children: JSX.Element }) => {
  const { authStatus, attributes } = useAuth();
  const location = useLocation();
  const [parameters] = useSearchParams();
  const redirect = parameters.get("redirect");

  if (authStatus === AuthStatus.SignedIn) {
    return (
      <Navigate
        to={
          attributes.walletType === 0 || attributes.walletType === 1
            ? redirect || "/"
            : "/wallet"
        }
        state={{ from: location }}
      />
    );
  }

  return children;
};
