import {
  Alert,
  Autocomplete,
  Collapse,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomebaseButton } from "../common/HomebaseButton";
import { RoundedInput } from "../common/Inputs";
import InfoIcon from "@mui/icons-material/Info";
import logo_stacked from "../static/logo_stacked.svg";
import { countries, states } from "../utils/constants";
import ConnectToPhantom from "../common/WalletConnect";
import { registerMailingList } from "../utils/email";

export const RegisterPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [wallet, setWallet] = useState("");
  const [parameters] = useSearchParams();
  const navigate = useNavigate();
  const referrer = parameters.get("referrer");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (currentStep === 0) {
      const email = data.get("email") as string;
      setEmail(email);
      setCurrentStep(1);
    } else {
      const email = data.get("email") as string;
      const password = data.get("password") as string;
      const firstName = data.get("firstName") as string;
      const lastName = data.get("lastName") as string;
      const attributes = {
        "custom:first_name": firstName,
        "custom:last_name": lastName,
        "custom:isAccredited": data.get("accredited") as string,
        "custom:country": country,
        "custom:kycStatus": "0",
        "custom:walletAddress": wallet,
        "custom:state": state,
      };

      signUp(email, password, firstName, lastName, attributes);
    }
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    attributes: any
  ) => {
    setLoading(true);
    try {
      registerMailingList(
        email,
        firstName,
        lastName,
        () => {},
        referrer?.toLowerCase() === "empiredao" ? "40168374" : undefined
      );
      const res = await Auth.signUp({
        username: email,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });
      analytics.identify(res.userSub, {
        email: email,
        country: attributes["custom:country"],
        isAccredited: attributes["custom:isAccredited"],
        firstName: firstName,
        lastName: lastName,
        entity: "empiredao",
      });

      analytics.track("submitted_signup");
      navigate(`verify?email=${email}`);
    } catch (error) {
      setAlert((error as any).message);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        justifyContent="center"
        maxWidth={420}
        margin="auto"
      >
        <img
          src={logo_stacked}
          alt="Homebase Logo"
          style={{ height: "128px", padding: "32px" }}
          className="clickable"
          onClick={() => navigate("/")}
        />
        <Stepper activeStep={currentStep} sx={{ width: "240px" }}>
          <Step key={0}>
            <StepLabel />
          </Step>
          <Step key={1}>
            <StepLabel />
          </Step>
          <Step key={2}>
            <StepLabel />
          </Step>
        </Stepper>
        {currentStep ? (
          <Box component="form" onSubmit={handleSubmit}>
            <Typography variant="h5" className="semi-bold" sx={{ mt: 2 }}>
              Welcome to Homebase
            </Typography>
            <Typography>We just need a few more details about you.</Typography>
            <RoundedInput
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              className="input-box"
              type="email"
              size="small"
              defaultValue={email}
              required
            />
            <RoundedInput
              margin="normal"
              fullWidth
              label="Password"
              name="password"
              className="input-box"
              type="password"
              size="small"
              autoComplete="new-password"
              required
            />
            <RoundedInput
              margin="normal"
              fullWidth
              label="First Name"
              name="firstName"
              className="input-box"
              type="text"
              size="small"
              required
            />
            <RoundedInput
              margin="normal"
              fullWidth
              label="Last Name"
              name="lastName"
              className="input-box"
              type="text"
              size="small"
              required
            />
            <FormControl
              fullWidth
              className="input-box"
              size="small"
              margin="normal"
              required
              sx={{ background: "#F3F3F3", borderRadius: "8px" }}
            >
              <InputLabel id="accredited">
                Are you an accredited investor?
              </InputLabel>
              <Select
                labelId="accredited"
                label="Are you an accredited investor?"
                name="accredited"
                defaultValue=""
              >
                <MenuItem value="1">Yes</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </Select>
              <Tooltip
                title={
                  <div>
                    <Typography variant="caption">
                      The Securities and Exchange Commission (SEC) defines an
                      accredited investor as someone who meets one of the
                      following three requirements:
                    </Typography>
                    <ol>
                      <li>
                        <Typography variant="caption">
                          Income: Has an annual income of at least $200,000, or
                          $300,000 if combined with a spouse’s income. This
                          level of income should be sustained from year to year.
                        </Typography>
                      </li>
                      <br />
                      <li>
                        <Typography variant="caption">
                          Professional: Is a “knowledgeable employee” of certain
                          investment funds or holds a valid Series 7, 65 or 82
                          license.
                        </Typography>
                      </li>
                      <br />
                      <li>
                        <Typography variant="caption">
                          Net Worth: Has a net worth of $1 million or more,
                          either individually or together with a spouse, but
                          excluding the value of a primary residence.
                        </Typography>
                      </li>
                    </ol>
                    <Typography variant="caption">
                      For more information about required documents for
                      accreditation, view our guide{" "}
                      <a
                        href="https://drive.google.com/file/d/11eX8fu5gU5rgNb38Jb3Dh7cKOXz1Y7Nl/view?usp=share_link"
                        className="clickable blue-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                      .
                    </Typography>
                  </div>
                }
                placement="top"
                arrow
              >
                <InfoIcon
                  sx={{
                    position: "absolute",
                    color: "#0f6eff",
                    height: "42px",
                    right: -32,
                  }}
                />
              </Tooltip>
            </FormControl>
            <Autocomplete
              fullWidth
              className="input-box"
              size="small"
              options={countries.map((country) => country.name)}
              renderInput={(params) => (
                <TextField {...params} label="Country of Residence" />
              )}
              value={country}
              onChange={(e, newCountry) => {
                setCountry(newCountry ? newCountry : "");
              }}
              sx={{
                background: "#F3F3F3",
                borderRadius: "8px",
                marginTop: "16px",
                marginBottom: "8px",
              }}
            />
            {country === "United States" && (
              <Autocomplete
                fullWidth
                className="input-box"
                size="small"
                options={states.map((state) => state.name)}
                renderInput={(params) => (
                  <TextField {...params} label="State of Residence" />
                )}
                value={state}
                onChange={(e, newState) => {
                  setState(newState ? newState : "");
                }}
                sx={{
                  background: "#F3F3F3",
                  borderRadius: "8px",
                  marginTop: "24px",
                  marginBottom: "8px",
                }}
              />
            )}
            <Collapse in={alert.length !== 0}>
              <Alert severity="error">{alert}</Alert>
            </Collapse>
            <Typography variant="body2" sx={{ margin: "8px 0px" }}>
              By signing up for an account, I consent to the Homebase{" "}
              <a
                href="https://homebasedao.notion.site/HOMEBASEDAO-INC-TERMS-OF-USE-531910d776624a17bd5ffbfcf51f9049"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://homebasedao.notion.site/HOMEBASEDAO-INC-PRIVACY-POLICY-fb9b0f97d89a4d61bb36b9494a5dde33"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Typography>
            <div style={{ margin: "8px 0px" }}>
              <HomebaseButton loading={loading} type="submit">
                Continue to Last Step
              </HomebaseButton>
            </div>
          </Box>
        ) : (
          <>
            <Typography variant="h5" className="semi-bold" sx={{ mt: 2 }}>
              Create an Account
            </Typography>
            <div>
              <Box component="form" onSubmit={handleSubmit}>
                <RoundedInput
                  margin="normal"
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  className="input-box"
                  type="email"
                  size="small"
                  required
                />
                <div style={{ margin: "8px 0px" }}>
                  <HomebaseButton type="submit">Sign Up</HomebaseButton>
                </div>
              </Box>
              <div style={{ width: "100%" }}>
                <Divider sx={{ mt: 2, mb: 2 }}>Or</Divider>
              </div>
              <div style={{ textAlign: "center" }}>
                <ConnectToPhantom
                  text="Wallet Sign Up"
                  callback={() => {
                    setCurrentStep(1);
                    setWallet(window.solana.publicKey.toString());
                  }}
                />
              </div>
              <Typography sx={{ marginTop: "8px" }}>
                Already have an account?{" "}
                <a href="login" className="clickable blue-text">
                  Sign in now
                </a>
              </Typography>
            </div>
          </>
        )}
      </Box>
    </Container>
  );
};
